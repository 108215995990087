<template>
	<div class="animated fadeIn">
		<!-- Filters Start -->
		<div class="row">
			<div class="col">
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
				<div class="btn btn-lg">
					{{ datePeriod }}
				</div>
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered(1)"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<template
						v-if="!isDashboard">
						<div
							class="col-12 col-md-4 col-sm-6 col-xs-12">
							<div
								class="form-group">
								<label
									for="uid"
									class="label">{{ translate('user_id') }}</label>
								<input
									id="uid"
									v-model="filters.uid"
									:placeholder="translate('type_uid')"
									type="text"
									name="uid"
									class="form-control">
							</div>
						</div>
					</template>

					<div :class="isDashboard ? 'col-md' : 'col-md-4 col-sm-6 col-xs-12'">
						<div
							class="form-group">
							<label
								for="distance"
								class="label">{{ translate('distance') }}</label>
							<input
								id="distance"
								v-model="filters.distance"
								:placeholder="translate('type_distance')"
								type="number"
								name="distance"
								class="form-control">
						</div>
					</div>
					<div :class="isDashboard ? 'col-md' : 'col-md-4 col-sm-6 col-xs-12'">
						<div
							class="form-group">
							<label
								for="dateRange"
								class="label">{{ translate('date_range') }}</label>
							<select
								id="dateRange"
								v-model="dateRange"
								name="dateRange"
								class="form-control">
								<option
									v-for="dateRangeOption in dateRanges"
									:key="dateRangeOption"
									:value="dateRangeOption">
									{{ translate(dateRangeOption) }}
								</option>
							</select>
						</div>
					</div>
					<div
						v-if="dateRange === dateRanges.custom"
						:class="isDashboard ? 'col-md' : 'col-sm col-md-4 col-sm-4 col-xs-12 mb-3'">
						<label
							for="startDate"
							class="label">{{ translate('start_date') }}</label>
						<v-date-picker
							id="startDate"
							v-model="startDate"
							:input-props="{ class: 'form-control' }"
							:formats="calendarConfig"
							name="startDate"
							show-caps />
					</div>
					<div
						v-if="dateRange === dateRanges.custom"
						:class="isDashboard ? 'col-md' : 'col-sm col-md-4 col-sm-4 col-xs-12 mb-3'">
						<label
							for="endDate"
							class="label">{{ translate('end_date') }}</label>
						<v-date-picker
							id="endate"
							v-model="endDate"
							:input-props="{ class: 'form-control' }"
							:formats="calendarConfig"
							name="endDate"
							show-caps />
					</div>
				</div>
			</template>
		</data-filter>
		<!-- Filters End -->
		<b-row>
			<b-col>
				<div class="table-responsive">
					<table
						class="table table-hover text-nowrap">
						<thead>
							<tr>
								<th />
								<th class="border-top-0">
									#
								</th>
								<th
									class="border-top-0">
									{{ translate('user_id') }}
								</th>
								<th class="border-top-0">
									{{ translate('username') }}
								</th>
								<th
									class="border-top-0">
									{{ translate('first_name') }}
								</th>
								<th class="border-top-0">
									{{ translate('distance') }}
								</th>
								<!-- <th class="border-top-0">
									{{ translate('total_distributors') }}
								</th> -->
								<th
									v-if="showSalesPercent"
									class="border-top-0 text-right">
									{{ translate('sales_percent') }}
								</th>
								<th
									class="border-top-0 text-right">
									{{ translate('total_sales') }}
								</th>
								<th
									class="border-top-0 text-right">
									{{ translate('duties') }}
								</th>
								<th
									class="border-top-0 text-right">
									{{ translate('total_refunds') }}
								</th>
								<th
									class="border-top-0 text-right">
									{{ translate('total_net_sales') }}
								</th>
								<th class="border-top-0">
									{{ translate('total_volume_left') }}
								</th>
								<th class="border-top-0">
									{{ translate('total_volume_right') }}
								</th>
							</tr>
						</thead>
						<tbody v-if="!loading && hasData">
							<template
								v-for="(item, index) in data"
								name="slide-fade">
								<tr
									:key="index"
									:class="{
										'pointer': item.attributes.country_detail.length > 0,
									}"
									@click="handleRowClick(item)">
									<td
										class="align-middle text-center">
										<em
											v-if="item.attributes.country_detail.length > 0"
											:class="`fas fa-chevron-${showChild(item) ? 'up' : 'down'}`" />
									</td>
									<td class="align-middle">
										{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
									</td>
									<td class="align-middle">
										<span
											v-if="isAdminOrCorporate"
											class="badge badge-primary pointer"
											@click="toDashboard({ distributorId: item.id.toString() })">
											{{ item.id }}
										</span>
										<span
											v-else
											class="badge badge-primary">
											{{ item.id }}
										</span>
									</td>
									<td class="align-middle">
										{{ item.attributes.username }}
									</td>
									<td
										class="align-middle">
										{{ item.attributes.first_name }}
									</td>
									<td class="align-middle">
										{{ item.attributes.distance == null ? translate('all') : item.attributes.distance }}
									</td>
									<!-- <td class="align-middle">
										{{ item.attributes.total_distributors }}
									</td> -->
									<td
										v-if="showSalesPercent"
										class="align-middle text-right font-weight-bold">
										<span v-if="showChild(item)">100%</span>
									</td>
									<td
										class="align-middle text-right"
										:class="{
											'font-weight-bold': showSalesPercent && showChild(item),
										}">
										{{ item.attributes.total_sales }}
									</td>
									<td
										class="align-middle text-right"
										:class="{
											'font-weight-bold': showSalesPercent && showChild(item),
										}">
										{{ item.attributes.total_taxes }}
									</td>
									<td
										class="align-middle text-right"
										:class="{
											'font-weight-bold': showSalesPercent && showChild(item),
										}">
										{{ item.attributes.total_refunds }}
									</td>
									<td
										class="align-middle text-right"
										:class="{
											'font-weight-bold': showSalesPercent && showChild(item),
										}">
										{{ item.attributes.total_net_sales }}
									</td>
									<td class="align-middle">
										{{ item.attributes.total_volume_left }}
									</td>
									<td class="align-middle">
										{{ item.attributes.total_volume_right }}
									</td>
								</tr>
								<template
									v-for="(countryData, cdIndex) in item.attributes.country_detail"
									name="slide-fade">
									<tr
										v-if="showChild(item)"
										:key="`${index}__${cdIndex}`"
										style="background-color: #f3f3f37a">
										<td
											class="align-middle text-right"
											colspan="5" />
										<td class="align-middle">
											<img
												:src="getFlag(countryData.country_code)"
												class="mr-2">{{ translate(countryData.country_code.toLowerCase()) }}
										</td>
										<td
											v-if="showSalesPercent"
											class="align-middle text-right text-bold">
											{{ countryData.sales_percent }}%
										</td>
										<td class="align-middle text-right">
											{{ countryData.total_sales }}
										</td>
										<td class="align-middle text-right">
											{{ countryData.total_taxes }}
										</td>
										<td class="align-middle text-right">
											{{ countryData.total_refunds }}
										</td>
										<td class="align-middle text-right">
											{{ countryData.total_net_sales }}
										</td>
										<td colspan="2" />
									</tr>

									<template
										v-for="(currencyData, currencydIndex) in countryData.currency_distribution"
										name="slide-fade">
										<tr
											v-if="showChild(item)"
											:key="`${index}__${cdIndex}__${currencydIndex}`"
											style="background-color: #f3f3f37a">
											<td
												class="align-middle text-right"
												colspan="5" />
											<td class="align-middle text-right">
												{{ currencyData.currency_code }}
											</td>
											<td
												v-if="showSalesPercent"
												class="align-middle text-right text-bold">
												{{ currencyData.sales_percent }}%
											</td>
											<td class="align-middle text-right">
												{{ currencyData.total_sales }}
											</td>
											<td class="align-middle text-right">
												{{ currencyData.total_taxes }}
											</td>
											<td class="align-middle text-right">
												{{ currencyData.total_refunds }}
											</td>
											<td class="align-middle text-right">
												{{ currencyData.total_net_sales }}
											</td>
											<td colspan="2" />
										</tr>
									</template>
								</template>
							</template>
						</tbody>
					</table>
				</div>
			</b-col>
		</b-row>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>

<script>
import { PAGINATION } from '@/settings/RequestReply';
import {
	Network, Grids, Countries, Report,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import {
	custom,
	DATE_TIME_RANGES,
	DATE_RANGES,
	V_CALENDAR_CONFIG as calendarConfig,
	MDY_FORMAT,
} from '@/settings/Dates';
import UnilevelSummary from '@/util/UnilevelSummary';
import { admin } from '@/settings/Roles';
import DataFilter from '@/components/DataFilter';

export default {
	name: 'UnilevelSummaryData',
	messages: [Network, Grids, Countries, Report],
	components: {
		DataFilter,
	},
	mixins: [FiltersParams, DashboardRedirect],
	data() {
		return {
			unilevelSummary: new UnilevelSummary(),
			dateRanges: { ...DATE_RANGES, custom },
			dateRange: DATE_RANGES.this_month,
			calendarConfig,
			admin,
			custom,
			rangeStart: '',
			rangeEnd: '',
			currentExpandIds: [],
			showSalesPercent: false,
		};
	},
	computed: {
		loading() {
			try {
				return !!this.unilevelSummary.data.loading;
			} catch (error) {
				return false;
			}
		},
		errors() {
			return this.unilevelSummary.data.errors;
		},
		pagination() {
			return this.unilevelSummary.data.pagination;
		},
		data() {
			try {
				const { data } = this.unilevelSummary.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			try {
				const response = this.data.length;
				return !!response;
			} catch (error) {
				return false;
			}
		},
		isDashboard() {
			return this.$route.name === 'DistributorsUnilevelSummary';
		},
		datePeriod() {
			return this.getDatePeriod();
		},
		isAdminOrCorporate() {
			return this.admin.includes(this.$user.details().type);
		},
	},
	mounted() {
		if (this.isAdminOrCorporate) {
			this.dateRanges = { ...DATE_TIME_RANGES, custom };
		}
		const { query } = this.$route;
		this.getSummary(query);
	},
	methods: {
		getSummary(query) {
			if (this.isDashboard) {
				query.uid = this.$route.params.distributorId;
			}

			this.unilevelSummary.getSummary(query).then(() => {
				const { start, end } = this.unilevelSummary.data.response.data.meta.period_dates;
				this.rangeStart = this.$moment(start.date).format(MDY_FORMAT);
				this.rangeEnd = this.$moment(end.date).format(MDY_FORMAT);

				if (this.data.length === 1) {
					this.handleRowClick(this.data[0]);
				}
			});
		},
		getDataFiltered(page) {
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}

			Object.keys(options).forEach((item) => {
				if (!options[item]) {
					delete options[item];
				}
			});

			this.getSummary(options);

			if (this.isDashboard) return;
			this.$router.push({ name: this.$route.name, query: options });
		},
		getDatePeriod() {
			const start = this.rangeStart;
			const end = this.rangeEnd;
			if (start !== '' && end !== '') {
				return this.translate('date_period', { start, end });
			}

			if (start !== '') {
				return this.translate('date_period_from', { start });
			}

			if (end !== '') {
				return this.translate('date_period_until', { end });
			}
			return '';
		},
		getFlag(src, isSmall) {
			let path = 'header';
			if (isSmall === true) {
				path = 'select';
			}
			try {
				/* eslint-disable global-require */
				/* eslint-disable import/no-dynamic-require */
				return require(`@/assets/images/common/countries/${path}/${src}.png`);
			} catch (error) {
				return require(`@/assets/images/common/countries/${path}/default.png`);
			}
		},
		handleRowClick(item) {
			if (item.attributes.country_detail.length === 0) {
				return;
			}
			if (this.currentExpandIds.includes(Number(item.id))) {
				this.currentExpandIds = this.currentExpandIds.filter((id) => id !== Number(item.id));
			} else {
				this.currentExpandIds.push(Number(item.id));
			}

			this.showSalesPercent = this.currentExpandIds.length > 0;
		},
		showChild(item) {
			return this.currentExpandIds.includes(Number(item.id));
		},
	},
};
</script>
<style scoped>
.slide-fade-enter-active {
	transition: all .5s ease;
}
.slide-fade-leave-active {
	transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to {
	transform: translateX(10px);
	opacity: 0;
}
</style>
