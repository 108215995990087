import {
	ACCEPT_UNILEVEL_SUMMARY_TERMS,
	GET_UNILEVEL_SUMMARY,
	GET_UNILEVEL_SUMMARY_DETAILS,
	GET_UNILEVEL_SUMMARY_TERMS,
} from '@/config/endpoint';
import Req from './AxiosRequest';

class UnilevelSummary {
	constructor() {
		this.data = new Req();
		this.errors = this.data.errors;
	}

	clear() {
		this.data.clear();
	}

	getUnilevelSummaryTerms() {
		const { method, endpoint } = GET_UNILEVEL_SUMMARY_TERMS;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	updateUnilevelSummaryTerms() {
		const { method, endpoint } = ACCEPT_UNILEVEL_SUMMARY_TERMS;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getSummary(options) {
		const { method, endpoint } = GET_UNILEVEL_SUMMARY;
		const queryParams = Req.setFilters(options);
		return this.data[method](endpoint(queryParams)).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getDetails(options) {
		const { method, endpoint } = GET_UNILEVEL_SUMMARY_DETAILS;
		const queryParams = Req.setFilters(options);
		return this.data[method](endpoint(queryParams)).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default UnilevelSummary;
